<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('indexmenu.线上客服')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <van-row>
          <van-cell
            icon="service-o"
            center
            :title="$t('login.serverurl')+' 1'"
            is-link url="chatlink.html"
          />
          <!-- <van-cell
            icon="service-o"
            center
            :title="$t('login.serverurl')+' 2'"
            is-link url="chatlink2.html"
          /> -->
        </van-row>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        kf1: '',
        kf2: '',
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    // 获取信息
    async getinfo() {
      const { data } = await this.$http.get('/home/index/serviceurl')
      if (data) {
        if (data.code === 200) {
          this.kf1 = data.data
          this.kf2 = data.data2
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing {
    margin-top: 1.2rem;
    .van-cell {
      height: 3.2rem;
      font-size: 0.93333rem;
    }
    .van-cell__title {
      text-align: left;
    }
    .van-cell__value {
      color: #000;
    }
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1rem;
      background: transparent !important;
    }
    .van-dropdown-menu /deep/ .van-dropdown-menu__title {
      color: #212121 !important;
    }
    /deep/.van-dropdown-item {
      margin-top: 1.1rem;
    }
    // 弹层
    .setting {
      width: 75%;
      border-radius: 0.4rem;
      color: #004ea3 !important;
      [class*='van-hairline']:after {
        border: none;
      }
      h3 {
        padding-top: 4%;
        padding-bottom: 4%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 1rem;
        border-bottom: 0.02667rem solid #e5e5e5;
      }
      .text {
        width: 100%;
        height: 55%;
        border: 0;
        .van-cell-group {
          background-color: #fff;
          margin-top: 1.33333rem;
          margin-bottom: 1.33333rem;
          .van-cell {
            font-size: 0.93333rem;
          }
          .van-field__left-icon {
            margin-right: 4px;
          }
          /deep/.van-field__label {
            width: 4.2rem;
            margin-right: 0.13333rem;
          }
          /deep/.van-cell__value {
            color: #000;
          }
          /deep/.van-field input {
            color: #000;
          }
          /deep/.van-field__control {
            font-size: 1rem !important;
            border-radius: 0.08rem !important;
          }
        }
      }
      button {
        width: 50%;
        padding-top: 4%;
        padding-bottom: 4%;
        border: 0;
        background-color: #fff;
        border-top: 0.02667rem solid #e5e5e5;
        color: #004ea3;
        font-size: 1.06667rem;
      }
    }
  }
}
</style>
